<template>
  <div class="cnt">
    <div v-show="showLoading" class="div_loading">
      <van-loading type="spinner" size="24px" />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// import heads from '@/compontens/heads.vue'
// import tool from '@/utils/tools'

export default {
  // components: { heads },
  data() {
    return {
      title_msg: '',
      showMore: true,
      showLoading: true,
      phpsid: Cookies.get('PHPSESSID') || 'PHPSESSID',
      cookie_uid: Cookies.get('uid') || '',
      cookie_uname: Cookies.get('username')
    }
  },
  created() {
    setTimeout(() => {
      this.goBack()
    }, 2000)
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.cnt {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div_loading {
  width: 60px;
}
</style>
